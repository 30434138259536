<script>
// eslint-disable-next-line no-unused-vars
import TablePage from '../../../../../components/table_page';
// eslint-disable-next-line no-unused-vars
import request from '../../../../../utils/request';
import FormDetail from './form_detail.vue';
import * as utils from '../../../utils/index';

export default {
  name: 'table-component',
  extends: TablePage,
  components: { FormDetail },
  data() {
    return {
      // requestUrl: '/sfa/sfaVisitDealerDetailController/findDealerDetailReportList',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  methods: {
    // 判断是否有‘yearMonth’字段
    hasYearMonth() {
      const index = this.searchList.findIndex((item) => item.field === 'yearMonth');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasYearMonth()) {
        this.formData.yearMonth = this.searchFormData.yearMonth || null;
      } else if (this.searchFormData.yearMonth) {
        delete this.searchFormData.yearMonth;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset' && this.hasYearMonth()) {
        this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
        this.formData.yearMonth = this.searchFormData.yearMonth;
      }
      return true;
    },
    /** @override 重写 */
    modalClick({ val, row }) {
      if (val.code === 'view') return this.btnCheck(row);
      return null;
    },
    cellClick({ row, column }) {
      if (column.property === 'clientCode') {
        this.formName = 'FormDetail';
        this.formConfig = {
          type: 'view',
          row,
        };
        this.modalConfig.title = '查看详情';
        this.openDrawer();
      }
    },
    btnCheck(row) {
      console.log('查看详情========', row);
      this.formName = 'FormDetail';
      this.formConfig.row = row;
      this.modalConfig.title = '查看详情';
      this.openDrawer();
    },
    /** @desc 折叠搜索条件 */
    foldSearchList(n) {
      n.map((v, k) => {
        const rowData = v;
        if (k > 3) {
          rowData.folding = true;
        }
        return rowData;
      });
      return n;
    },
  },
  created() {
    this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
    this.getConfigList('sfaCenter_visitManage_dealerVisitDetail');
  },
};
</script>
