<script>
import Form, { formCreate } from '../../../../../components/form';
import DetailInfo from './components/detail.vue';

formCreate.component('sfaDealerVisitDetail', DetailInfo);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: false,
      },
    };
  },
  methods: {
    /** @desc 设置详情的value值 */
    setDetailValue() {
      this.setValue({
        row: { ...this.formConfig.row },
      });
    },
  },
  async created() {
    await this.getFormRule('sfaCenter_visitManage_dealerVisitDetail_check');
    this.setDetailValue();
    console.log('form_detail========', this.formConfig.row);
  },
};
</script>
