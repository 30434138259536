<template>
  <el-form label-width="120px">
    <el-row>
      <el-col :span="12">
        <el-form-item label="客户类型">
          <el-input v-model="clientTypeDesc" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="客户编码">
          <el-input v-model="clientCode" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="客户名称">
          <el-input v-model="clientName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="拜访人员账号">
          <el-input v-model="visitUserName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="拜访人姓名">
          <el-input v-model="visitRealName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="拜访人职位">
          <el-input v-model="visitPosName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="所属区域">
          <el-input v-model="visitParentOrgName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="所属办事处">
          <el-input v-model="visitOrgName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="进店时间">
          <el-input v-model="inStore" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="进店地址">
          <el-input v-model="inStoreAddress" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="离店时间">
          <el-input v-model="outStore" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="离店地址">
          <el-input v-model="outStoreAddress" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="拜访总结">
          <el-input v-model="visitSummary" type="textarea" :rows="3" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="img-url">
        <el-form-item label="进离店打卡照片">
          <el-image v-for="(item,index) in photoList" :key="index" :preview-src-list="[item.picUrl+item.picPath]" :src="item.picUrl+item.picPath"></el-image>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
      default() {
        return {
          clientType: '', // 客户类型
          clientCode: '', // 客户编码
          clientName: '', // 客户名称
          clientTypeDesc: '', // 客户类型
          visitUserName: '', // 拜访人员账号
          visitRealName: '', // 拜访人姓名
          visitPosName: '', // 拜访人职位
          visitParentOrgName: '', // 所属区域
          visitOrgName: '', // 所属办事处
          inStore: '', // 进店时间
          inStoreAddress: '', // 进店地址
          outStore: '', // 离店时间
          outStoreAddress: '', // 离店地址
          visitSummary: '', // 拜访总结
          photoList: [], // 进离店打卡照片
        };
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        console.log('监听查看=======', val);
        if (val) {
          this.clientType = val.clientType;// 客户类型
          this.clientCode = val.clientCode;// 客户编码
          this.clientName = val.clientName;// 客户名称
          this.clientTypeDesc = val.clientTypeDesc;// 客户类型
          this.visitUserName = val.visitUserName;// 拜访人员账号
          this.visitRealName = val.visitRealName;// 拜访人姓名
          this.visitPosName = val.visitPosName;// 拜访人职位
          this.visitParentOrgName = val.visitParentOrgName;// 所属区域
          this.visitOrgName = val.visitOrgName;// 所属办事处
          this.inStore = val.inStore;// 进店时间
          this.inStoreAddress = val.inStoreAddress;// 进店地址
          this.outStore = val.outStore;// 离店时间
          this.outStoreAddress = val.outStoreAddress;// 离店地址
          this.visitSummary = val.visitSummary;// 拜访总结
          this.photoList = val.photoList ? val.photoList : [];// 进离店打卡照片
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      clientType: '', // 客户类型
      clientCode: '', // 客户编码
      clientName: '', // 客户名称
      clientTypeDesc: '', // 客户类型
      visitUserName: '', // 拜访人员账号
      visitRealName: '', // 拜访人姓名
      visitPosName: '', // 拜访人职位
      visitParentOrgName: '', // 所属区域
      visitOrgName: '', // 所属办事处
      inStore: '', // 进店时间
      inStoreAddress: '', // 进店地址
      outStore: '', // 离店时间
      outStoreAddress: '', // 离店地址
      visitSummary: '', // 拜访总结
      photoList: [], // 进离店打卡照片
    };
  },
};
</script>

<style lang="less" scoped>
  /deep/.el-image__inner ,
  /deep/ .el-image__error{
    width: 120px;
    height: 120px;
    margin-right: 6px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    outline: 0;
    object-fit: contain;
  }
  /deep/ .img-url .el-form-item__content > div{
    width: auto;
  }
</style>
